import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';
import * as Events from '../lib/events';

export default el => {
    const dom = $(el);
    const close = dom.find('[data-close]');
    const wrapper = dom.find('[data-wrapper]');
    const content = dom.find('[data-content]');

    const box = wrapper.get(0);

    let active = false;
    let opener = null;

    const keyHandler = e => {
        const key = e.keyCode || e.which;
        if (active && key === 27) {
            Dispatch.emit(Events.REMINDER_CLOSE);
        }
    };

    const onOpen = () => {
        if (!active) {
            active = true;
            opener = document.activeElement;
            document.addEventListener('keyup', keyHandler);
            gsap.set(box, {
                opacity: 0,
                scale: 0.8
            });

            gsap.to(el, {
                duration: 0.35,
                autoAlpha: 1,
                ease: 'power1.inOut'
            });
            gsap.to(box, {
                duration: 0.35,
                delay: 0.25,
                opacity: 1,
                ease: 'sine.out'
            });
            gsap.to(box, {
                duration: 0.35,
                delay: 0.25,
                scale: 1,
                ease: 'power2.out'
            });

            setTimeout(() => {
                Viewport.lockTabbing(dom, close);
            }, 10);
        }
    };

    const onClose = () => {
        if (active) {
            active = false;
            document.removeEventListener('keyup', keyHandler);
            gsap.to(box, {
                duration: 0.5,
                opacity: 0,
                scale: 0.85,
                ease: 'power2.inOut'
            });
            gsap.to(el, {
                duration: 0.35,
                delay: 0.25,
                autoAlpha: 0,
                ease: 'power1.inOut',
                onComplete() {
                    content.empty();
                }
            });
            Viewport.releaseTabbing(opener);
        }
    };

    const addScript = markethypeReminderEmbed => {
        const contentNode = content.get(0);
        const scriptNode = $('<div></div>').html(markethypeReminderEmbed).find('script').get(0);

        const script = document.createElement('script');
        script.setAttribute('async', 'true');
        script.setAttribute('onload', scriptNode.getAttribute('onload'));
        script.setAttribute('src', scriptNode.getAttribute('src'));

        contentNode.appendChild(script);
    };

    const onClick = e => {
        e.preventDefault();
        const target = $(e.triggerTarget);
        addScript(target.data('reminder-button').markethypeReminderEmbed);
        Dispatch.emit(Events.REMINDER_OPEN);
    };

    const init = () => {
        Dispatch.on(Events.REMINDER_OPEN, onOpen);
        Dispatch.on(Events.REMINDER_CLOSE, onClose);

        close.on('click', onClose);

        dom.on('click', e => {
            if (!wrapper.get(0)
                .contains(e.target)) {
                Dispatch.emit(Events.REMINDER_CLOSE);
            }
        });

        $('[data-reminder-button]').on('click', onClick);
    };

    const destroy = () => {
        Dispatch.off(Events.REMINDER_OPEN, onOpen);
        Dispatch.off(Events.REMINDER_CLOSE, onClose);
        dom.off('click');
        close.off('click');
        $('[data-reminder-button]').off('click', onClick);
    };

    return {
        init,
        destroy
    };
};
